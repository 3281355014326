import { apiOrderPay, apiOrderClose, apiOrderTakedelivery } from "@/api/order/order"
export default {
    methods: {
        /**
         * การชําระเงินตามคําสั่งซื้อ
         * @param {Object} out_trade_no
         */
        orderPay(orderData) {
            if (orderData.adjust_money == 0) {
                apiOrderPay({
                    order_ids: orderData.order_id
                }).then(res => {
                    if (res.code >= 0) {
                        this.$router.push({
                            path: "/pay",
                            query: {
                                code: res.data
                            }
                        })
                    } else {
                        this.$notify({
                            message: res.message,
                            title: "คำเตือน",type: "warning"
                        })
                    }
                })
            } else {
                this.$confirm("ผู้ขายได้ปรับจํานวนเงินที่ชําระเป็น" + orderData.pay_money + " บาท，จะดําเนินการชําระเงินหรือไม่？", "พร้อมท์", {
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: "ยกเลิก",
                    title: "คำเตือน",type: "warning"
                }).then(() => {
                    apiOrderPay({
                        order_ids: orderData.order_id
                    }).then(res => {
                        if (res.code >= 0) {
                            this.$router.push({
                                path: "/pay",
                                query: {
                                    code: res.data
                                }
                            })
                        } else {
                            this.$notify({
                                message: res.message,
                                title: "คำเตือน",type: "warning"
                            })
                        }
                    })
                })
            }
        },
        /**
         * ปิดคําสั่งซื้อขาย
         * @param {Object} order_id
         */
        orderClose(order_id, callback) {
            this.$confirm("คุณแน่ใจหรือไม่ว่าต้องการปิดคําสั่งซื้อขาย？", "พร้อมท์", {
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                title: "คำเตือน",type: "warning"
            }).then(() => {
                apiOrderClose({
                    order_id
                }).then(res => {
                    this.$notify({
                       title: 'เรียบร้อยแล้ว',
                        message: "คําสั่งถูกปิดเรียบร้อยแล้ว",
                        title: 'เรียบร้อยแล้ว',type: "success"
                    })
                    typeof callback == "function" && callback()
                })
            })
        },
        /**
         * ใบเสร็จรับเงินคําสั่งซื้อ
         * @param {Object} order_id
         */
        orderDelivery(order_id, callback) {
            this.$confirm("คุณแน่ใจหรือไม่ว่าได้รับสินค้าแล้ว？", "พร้อมท์", {
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                title: "คำเตือน",type: "warning"
            }).then(() => {
                apiOrderTakedelivery({
                    order_id
                }).then(res => {
                   this.$notify({
                      title: 'เรียบร้อยแล้ว',
                        message: "ได้รับคําสั่งซื้อเรียบร้อยแล้ว",
                        title: 'เรียบร้อยแล้ว',type: "success"
                    })
                    typeof callback == "function" && callback()
                })
            })
        }
    }
}
